.bookmark-circles {
  display: inline-block;
}

.circle-container {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  max-width: 120px;
  gap: 4px;
}

.initial-circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bs-primary);
  color: white;
  font-size: 14px;
  font-weight: 500;
}

.more-circle {
  background-color: var(--bs-secondary);
}

.popover-circles {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 300px;
  overflow-y: auto;
}

.popover-user {
  display: flex;
  align-items: center;
  gap: 8px;
}

.user-name {
  font-size: 14px;
} 